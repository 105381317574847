import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280.000000 1280.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"   transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">

<path d="M6608 9854 c-4 -3 -28 -5 -55 -5 -82 1 -143 -9 -143 -25 0 -8 -7 -14
-15 -14 -12 0 -42 -39 -33 -43 2 -1 13 -8 25 -14 31 -18 69 -16 98 3 22 14 25
14 25 1 0 -8 8 -21 17 -28 15 -13 16 -12 11 9 -4 12 -2 22 3 22 5 0 9 -8 9
-17 1 -17 1 -17 11 0 9 16 12 16 22 4 15 -18 79 -40 86 -30 3 5 15 15 25 23
18 14 19 14 6 -10 l-13 -24 28 18 c15 10 41 22 57 27 25 7 30 5 38 -14 5 -12
9 -17 9 -12 1 15 83 37 104 29 23 -9 21 -24 -3 -24 -11 0 -20 -6 -20 -12 0
-23 -67 -58 -109 -58 -22 0 -43 5 -46 10 -13 20 -53 10 -79 -21 -46 -54 -39
-56 103 -38 116 14 134 19 167 45 20 16 44 34 53 41 22 16 83 3 87 -19 1 -10
25 -44 53 -76 28 -32 51 -64 51 -70 0 -7 8 -12 18 -12 10 0 30 -17 43 -37 19
-29 31 -37 49 -35 13 1 26 4 28 7 10 10 -11 58 -28 62 -10 3 -21 15 -24 27 -7
29 -67 85 -73 69 -6 -18 -33 -16 -33 1 0 8 6 17 13 19 7 4 -3 19 -28 41 -22
20 -51 46 -65 59 -41 38 -128 73 -148 60 -29 -18 -82 -26 -82 -13 0 19 -44 50
-63 44 -11 -4 -16 -1 -15 7 4 20 -91 36 -103 18 -6 -9 -12 -10 -16 -4 -9 12
-37 18 -45 9z m122 -34 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10
2 0 4 -4 4 -10z m78 -34 c-13 -14 -28 -12 -28 5 0 5 9 9 20 9 17 0 19 -2 8
-14z m162 -71 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8 23 11 12 23 8 23 -8z"/>
<path d="M6258 9787 c-74 -30 -96 -47 -63 -47 18 0 19 -11 3 -29 -11 -11 -8
-13 16 -8 23 4 37 -3 72 -35 41 -38 47 -40 151 -54 124 -16 163 -17 163 -6 0
17 -43 53 -56 48 -8 -3 -14 1 -14 9 0 17 -26 20 -35 5 -3 -5 -27 -10 -53 -10
-40 0 -53 5 -84 36 -37 34 -51 74 -26 74 9 0 9 3 0 12 -9 9 -7 14 6 24 26 20
4 15 -80 -19z m42 -78 c0 -5 -7 -9 -15 -9 -15 0 -20 12 -9 23 8 8 24 -1 24
-14z"/>
<path d="M6112 9696 c-35 -35 -43 -48 -37 -66 6 -20 4 -22 -15 -17 -17 4 -26
-2 -42 -29 -11 -19 -25 -34 -31 -34 -13 0 -57 -48 -57 -62 0 -13 7 -10 36 17
15 14 31 25 36 25 20 0 78 46 78 63 0 9 4 17 8 17 12 0 92 77 92 89 0 5 -5 13
-10 16 -6 4 -8 11 -5 16 15 23 -14 4 -53 -35z"/>
<path d="M6988 9603 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6160 9590 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M6130 9541 c0 -25 -20 -38 -31 -20 -7 11 -13 10 -30 -8 l-22 -22 25
-56 c25 -55 38 -56 38 -4 0 24 25 25 42 0 7 -10 23 -21 35 -25 32 -10 37 -35
11 -56 -28 -22 -17 -37 16 -22 26 12 46 0 46 -27 0 -12 -3 -12 -12 -3 -18 18
-56 -22 -51 -52 4 -18 17 -27 65 -42 36 -11 66 -28 75 -41 12 -17 21 -20 49
-16 26 4 39 1 53 -13 17 -18 17 -19 -23 -29 -45 -12 -60 -39 -25 -48 11 -3 18
-10 15 -15 -3 -5 5 -21 19 -36 29 -31 40 -34 25 -5 -6 11 -10 28 -8 37 3 15 5
15 18 1 13 -15 17 -14 44 2 22 13 35 15 54 8 16 -6 29 -6 37 1 14 12 81 4 90
-10 6 -10 -72 -24 -120 -21 -22 2 -43 8 -47 15 -4 6 -5 -6 -1 -26 4 -25 2 -38
-6 -38 -20 0 -12 -48 11 -64 32 -22 48 -20 45 6 -2 19 5 24 36 32 44 11 72 0
72 -30 0 -13 8 -20 26 -22 22 -3 29 2 38 30 7 18 13 35 14 38 1 2 -14 5 -35 5
-62 3 -95 55 -34 55 19 0 42 7 51 17 19 19 75 26 75 9 -1 -6 -7 -28 -14 -48
-11 -32 -11 -38 1 -42 21 -9 35 3 28 24 -6 19 38 53 85 65 14 3 31 15 38 26
10 16 9 19 -6 19 -10 0 -24 6 -31 13 -10 10 -15 8 -28 -8 -11 -14 -17 -16 -20
-7 -3 7 -23 12 -52 12 -56 0 -64 6 -50 32 9 17 17 19 57 13 26 -4 51 -11 57
-16 5 -5 28 -9 51 -9 l42 0 -27 29 c-23 24 -36 29 -91 33 -54 4 -68 1 -87 -16
-13 -11 -23 -27 -23 -35 0 -13 -2 -13 -9 -3 -11 19 -102 6 -95 -13 7 -18 -15
-30 -29 -16 -17 17 20 50 71 63 l45 11 -44 17 c-36 13 -49 14 -73 4 -16 -6
-47 -9 -68 -7 -46 7 -52 -12 -7 -21 45 -9 25 -27 -22 -20 -26 4 -39 3 -39 -5
0 -6 -4 -11 -10 -11 -5 0 -10 11 -10 25 0 14 -5 25 -12 25 -7 0 -32 7 -55 16
l-43 17 0 56 c-1 62 -13 85 -54 101 l-27 10 29 25 c26 22 29 29 23 58 -9 40
-29 77 -40 77 -15 0 -31 -26 -37 -59 -4 -18 -14 -35 -22 -38 -18 -7 -52 34
-51 62 2 42 -1 55 -16 55 -8 0 -15 -9 -15 -19z m20 -51 c0 -5 -7 -10 -15 -10
-8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m460 -350 c0 -13 -11 -13
-30 0 -12 8 -11 10 8 10 12 0 22 -4 22 -10z m80 -40 c0 -5 -4 -10 -10 -10 -5
0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"/>
<path d="M7090 9531 c0 -6 -27 -10 -67 -8 l-68 2 -19 -38 -18 -39 31 -32 32
-33 -30 -12 c-34 -15 -32 -30 5 -72 22 -22 23 -27 10 -45 -14 -19 -15 -19 -29
0 -8 10 -19 16 -25 13 -17 -11 -2 -47 22 -51 58 -12 84 -14 90 -9 3 4 34 8 68
11 52 3 69 9 99 35 49 42 58 62 19 42 -16 -8 -36 -15 -45 -15 -8 0 -36 -13
-62 -29 -26 -16 -51 -27 -55 -25 -12 8 -10 68 3 81 11 11 -7 53 -23 53 -5 0
-8 6 -8 14 0 8 22 22 50 32 49 18 66 43 39 60 -6 3 -9 20 -7 36 3 16 1 31 -4
34 -4 3 -8 1 -8 -5z m-96 -27 c4 -9 4 -19 2 -21 -8 -8 -26 8 -26 23 0 19 16
18 24 -2z m47 -55 c9 -17 8 -25 -5 -37 -14 -14 -17 -14 -35 2 -16 15 -18 21
-8 37 16 25 34 24 48 -2z m-21 -134 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8 23
11 12 23 8 23 -8z"/>
<path d="M5930 9371 c0 -13 29 -43 85 -88 69 -55 87 -63 120 -55 l29 8 -47 27
c-25 15 -50 27 -56 27 -18 0 -69 35 -91 63 -21 27 -40 35 -40 18z"/>
<path d="M7283 9357 c-34 -37 -39 -51 -14 -38 20 11 46 49 39 57 -3 2 -14 -6
-25 -19z"/>
<path d="M6105 9330 c3 -5 14 -10 23 -10 15 0 15 2 2 10 -20 13 -33 13 -25 0z"/>
<path d="M7114 9332 c-9 -5 -8 -11 2 -20 12 -11 14 -9 14 8 0 22 0 23 -16 12z"/>
<path d="M6467 9234 c-13 -13 -7 -44 8 -44 16 0 17 5 6 33 -3 10 -10 15 -14
11z"/>
<path d="M6106 9171 c-3 -4 -34 -19 -68 -31 -125 -46 -200 -93 -278 -174 -41
-42 -76 -83 -78 -90 -4 -19 28 -29 34 -11 7 17 34 21 34 5 0 -5 -11 -17 -24
-25 -13 -9 -26 -25 -29 -36 -6 -23 -27 -17 -27 7 0 23 -27 16 -55 -13 -16 -18
-24 -36 -21 -48 3 -13 -4 -26 -20 -38 -13 -10 -24 -13 -24 -7 0 5 -4 10 -9 10
-13 0 -84 -90 -86 -109 0 -9 -4 -21 -8 -28 -5 -7 0 -9 13 -6 11 3 20 11 20 19
0 21 63 74 88 74 26 0 28 -6 6 -28 -13 -12 -14 -17 -4 -20 17 -6 50 34 50 59
0 10 19 31 45 49 27 19 45 39 45 50 0 11 7 20 15 20 9 0 18 7 21 15 9 23 72
83 95 90 13 5 18 12 14 24 -8 27 35 51 91 51 45 0 51 -3 120 -70 57 -55 78
-70 102 -70 16 0 81 -9 144 -19 62 -11 138 -22 168 -26 l55 -6 -40 35 c-22 19
-52 47 -68 62 -26 26 -30 27 -106 21 -75 -6 -79 -5 -102 19 -13 15 -43 40 -67
56 -35 24 -41 32 -31 44 8 10 8 14 0 14 -6 0 -11 7 -11 15 0 8 -13 21 -30 30
-16 9 -30 20 -30 26 0 5 13 3 30 -6 16 -8 39 -15 52 -15 13 0 34 -7 46 -16 12
-8 27 -12 32 -9 18 11 11 24 -17 31 -58 14 -81 31 -76 59 5 25 -1 33 -11 16z
m-173 -138 c-8 -26 -33 -43 -61 -43 -33 0 -27 41 8 51 46 13 60 10 53 -8z
m146 -59 c-28 -34 -60 -3 -38 37 l12 23 19 -23 c14 -18 16 -27 7 -37z m321
-124 c0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 9 10 20 10 11 0 20 -4 20
-10z"/>
<path d="M7131 9134 c-13 -13 -34 -24 -45 -24 -25 -1 -56 -22 -56 -39 0 -6 -7
-11 -15 -11 -8 0 -15 -4 -15 -8 0 -21 15 -19 45 3 38 30 52 31 84 6 27 -21 20
-45 -10 -36 -10 4 -29 3 -41 -1 l-23 -7 25 -9 25 -9 -22 -17 c-11 -9 -38 -30
-59 -47 -40 -30 -79 -38 -138 -25 -27 5 -39 -1 -98 -48 -38 -30 -68 -58 -68
-63 0 -5 12 -6 28 -4 15 3 90 14 167 25 77 12 155 23 173 26 19 3 32 11 32 19
0 8 4 15 9 15 5 0 32 23 61 50 29 28 58 51 64 51 22 2 51 -3 62 -12 5 -4 19
-6 30 -3 26 7 55 -23 35 -36 -16 -9 -6 -30 15 -30 8 0 14 -9 14 -21 0 -12 8
-23 18 -26 10 -2 26 -17 35 -32 9 -16 30 -43 47 -61 16 -18 32 -43 34 -57 7
-38 26 -55 53 -48 20 5 26 1 34 -24 6 -17 17 -28 27 -27 12 1 16 -5 14 -19 -3
-22 41 -69 53 -57 3 4 12 7 18 8 7 0 20 2 29 2 48 5 22 102 -32 120 -22 8 -30
17 -30 34 0 21 -102 148 -119 148 -3 0 -11 -10 -16 -22 -6 -13 -20 -24 -32
-26 -20 -3 -23 1 -23 28 0 23 5 33 20 37 32 8 24 27 -32 77 -29 25 -71 64 -93
85 -34 34 -232 143 -253 140 -4 -1 -18 -12 -31 -25z m69 -35 c0 -5 -7 -9 -15
-9 -15 0 -20 12 -9 23 8 8 24 -1 24 -14z m10 -39 c0 -5 -5 -10 -11 -10 -5 0
-7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m210 -90 c0 -6 -3 -10 -7 -10 -10
0 -53 63 -53 76 1 12 60 -53 60 -66z m-213 44 c6 -18 -23 -48 -41 -41 -16 6
-16 8 1 32 18 24 34 28 40 9z m470 -366 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
-3 4 -12 1 -19z"/>
<path d="M6303 9060 c15 -22 67 -65 67 -55 0 8 -65 75 -74 75 -4 0 -1 -9 7
-20z"/>
<path d="M6390 8975 c7 -8 17 -15 22 -15 5 0 3 7 -6 15 -8 8 -18 15 -22 15 -3
0 -1 -7 6 -15z"/>
<path d="M5803 8838 c-19 -25 -24 -38 -14 -38 11 0 35 39 28 46 -2 3 -9 -1
-14 -8z"/>
<path d="M5962 8808 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"/>
<path d="M7216 8805 c-11 -8 -18 -17 -15 -19 9 -9 68 7 73 20 7 19 -33 18 -58
-1z"/>
<path d="M5854 8793 c-20 -62 -27 -73 -39 -63 -10 8 -16 -1 -25 -40 -9 -38
-16 -50 -29 -48 -9 2 -17 14 -19 26 -4 28 -9 27 -62 -22 -35 -32 -41 -42 -32
-55 18 -26 58 -113 72 -153 13 -43 30 -50 30 -13 0 40 21 125 31 125 5 0 19
-16 31 -35 13 -19 39 -41 59 -49 27 -11 109 -79 109 -90 0 -2 -13 -1 -29 2
-22 3 -36 -2 -55 -21 -24 -24 -25 -29 -13 -54 11 -26 12 -26 15 -5 2 15 10 22
26 22 12 0 27 5 33 11 16 16 31 4 48 -38 19 -46 10 -56 -19 -20 l-23 28 -23
-34 c-13 -20 -30 -33 -37 -30 -17 7 -16 -9 2 -52 l14 -35 -27 6 c-38 10 -132
55 -132 64 0 10 -48 30 -74 30 -12 0 -35 14 -51 30 -17 17 -35 28 -44 25 -15
-6 -51 21 -51 40 0 6 -12 22 -27 35 -16 12 -36 30 -46 39 -17 14 -18 14 -15
-13 3 -27 -1 -31 -62 -56 -36 -15 -96 -38 -135 -50 -123 -41 -227 -95 -275
-144 -24 -25 -48 -46 -53 -46 -12 0 -131 -102 -174 -150 l-33 -35 29 -7 c47
-13 57 -33 35 -73 -25 -48 -38 -49 -69 -9 -14 19 -34 34 -44 34 -17 0 -121
-94 -121 -110 0 -5 10 -10 22 -12 49 -7 23 -28 -93 -76 -4 -2 -5 6 -2 17 3 12
10 21 16 21 6 0 8 3 4 6 -11 11 -86 -56 -103 -92 -9 -18 -20 -31 -26 -28 -5 3
-25 -6 -44 -21 -25 -20 -33 -32 -29 -46 6 -19 -17 -64 -27 -54 -3 3 -8 -1 -11
-9 -4 -9 2 -16 15 -19 14 -4 22 0 25 14 6 20 23 27 23 8 0 -8 5 -8 15 1 8 7
15 23 15 36 0 25 34 54 62 54 10 0 18 5 18 10 0 6 17 18 38 26 20 9 45 20 55
25 12 7 19 5 23 -6 10 -26 -4 -47 -26 -40 -16 5 -20 2 -20 -19 0 -14 4 -27 9
-30 15 -9 41 5 41 21 0 13 3 13 16 3 22 -19 45 8 51 62 3 23 8 46 12 51 4 4
45 26 92 50 81 41 84 43 81 75 -2 28 1 32 22 32 16 0 32 11 46 30 12 17 35 37
51 46 17 8 46 33 66 55 25 27 41 38 54 34 18 -6 59 13 59 27 0 5 -6 8 -14 8
-32 0 -25 21 14 45 27 17 40 31 36 41 -6 17 5 19 21 3 8 -8 13 -8 17 0 11 18
-3 31 -27 27 -12 -3 -39 -8 -59 -11 l-38 -6 15 28 c8 15 17 36 20 47 5 18 6
18 24 2 12 -11 20 -13 23 -6 2 6 39 20 82 30 94 24 111 25 72 5 -15 -8 -35
-15 -42 -15 -8 0 -14 -9 -14 -21 0 -11 -9 -28 -21 -37 -45 -38 6 -65 81 -43
27 9 86 -15 110 -44 8 -9 48 -42 90 -73 41 -31 84 -65 94 -74 22 -20 34 -23
206 -43 69 -8 140 -18 158 -21 17 -3 32 -2 32 3 0 30 -157 153 -328 257 -34
20 -62 42 -62 47 0 5 12 14 28 20 l27 11 -32 12 c-35 12 -46 29 -21 34 8 2 46
-18 84 -44 65 -43 73 -46 144 -49 56 -2 105 -13 193 -43 65 -22 120 -42 121
-44 1 -1 7 -16 14 -32 13 -28 13 -28 77 -21 58 6 64 5 75 -15 6 -12 26 -28 43
-35 31 -14 30 -14 -43 -15 -75 0 -113 -11 -91 -24 22 -14 12 -33 -16 -29 -27
4 -28 4 -8 -12 11 -9 32 -21 46 -27 32 -11 49 -53 19 -45 -18 5 -18 3 -3 -19
8 -13 20 -24 26 -24 6 0 28 -17 49 -37 45 -44 48 -30 13 45 -14 30 -25 58 -25
63 0 20 30 7 47 -21 20 -34 33 -39 33 -11 0 52 41 17 54 -47 7 -28 4 -33 -18
-43 -23 -11 -25 -16 -20 -53 8 -49 20 -66 45 -66 11 0 19 -7 19 -15 0 -10 10
-14 35 -13 39 1 43 9 19 33 -15 15 -15 17 1 35 10 11 29 20 42 20 12 0 23 5
23 10 0 6 14 10 30 10 17 0 30 -4 30 -10 0 -5 14 -10 31 -10 28 0 30 -2 27
-32 -4 -41 22 -66 62 -60 29 4 50 35 50 73 0 11 4 18 9 14 5 -3 13 4 16 15 8
24 -12 53 -28 43 -12 -7 -117 -4 -131 4 -5 3 2 12 15 19 23 13 23 13 3 14 -23
0 -48 23 -37 33 3 4 32 7 62 7 58 0 91 20 91 55 0 11 13 14 58 12 l58 -2 -6
-36 c-4 -19 -14 -50 -23 -69 -23 -46 -22 -54 15 -68 23 -8 35 -9 45 -1 17 15
17 29 -2 29 -21 0 -14 52 10 76 9 10 30 19 46 20 36 2 52 13 45 30 -4 10 2 12
22 8 18 -4 38 2 61 18 45 30 50 48 14 48 l-28 1 25 15 c25 14 25 14 -12 12
-21 -1 -43 3 -50 10 -16 16 -38 15 -38 -1 0 -15 -31 -47 -44 -47 -5 0 -5 12
-2 26 l7 26 -93 -7 c-79 -6 -171 -20 -265 -40 -16 -4 -21 -1 -19 13 2 14 19
19 87 27 97 12 133 28 126 56 -3 10 -2 21 2 23 4 3 49 -3 101 -13 52 -10 121
-22 153 -26 32 -3 74 -13 94 -21 23 -10 39 -12 45 -6 7 7 -8 19 -44 36 -33 16
-59 36 -66 51 -8 17 -20 25 -36 25 -13 0 -26 5 -28 11 -5 14 -100 13 -148 -1
-44 -13 -53 -13 -45 0 3 6 17 10 30 10 32 0 39 15 36 73 -4 82 -20 102 -21 27
0 -73 -4 -80 -42 -97 -17 -8 -26 -18 -22 -26 3 -8 -6 -18 -21 -25 -14 -7 -24
-16 -21 -21 3 -5 -27 -9 -67 -9 -39 0 -84 -5 -99 -11 -17 -6 -28 -7 -28 -1 0
5 -10 10 -22 10 -13 0 -50 4 -83 9 -78 12 -188 13 -160 1 18 -8 18 -9 2 -9
-10 -1 -20 -8 -23 -16 -11 -28 -24 -15 -24 24 0 51 11 53 157 29 143 -25 126
-25 251 -2 109 20 113 32 9 33 -51 1 -58 4 -67 25 -13 35 -25 38 -41 10 -9
-17 -19 -24 -31 -21 -10 3 -16 2 -13 -2 2 -4 -38 -9 -90 -10 l-95 -2 0 28 c0
34 -21 77 -35 72 -5 -2 -9 -24 -7 -48 2 -26 -1 -47 -8 -51 -13 -8 -98 10 -170
36 -43 16 -48 20 -38 37 5 11 7 33 4 51 -3 17 -8 47 -11 66 -18 108 -24 118
-76 135 -27 9 -49 20 -49 24 0 7 67 67 90 81 13 8 -14 120 -39 162 -20 33 -27
37 -49 32 -38 -9 -46 -15 -32 -24 9 -6 8 -15 -4 -38 -9 -17 -16 -42 -16 -55 0
-21 -36 -67 -53 -67 -11 0 -71 129 -64 137 4 3 32 11 64 18 l58 13 -58 1 c-40
1 -59 5 -63 15 -6 15 18 70 44 99 14 16 13 17 -7 17 -12 0 -24 -8 -27 -17z
m-20 -212 l6 -33 -19 23 c-18 22 -19 23 -45 5 l-27 -17 12 23 c6 13 19 31 27
41 20 22 37 7 46 -42z m-114 -51 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6
5 10 10 10 6 0 10 -4 10 -10z m-227 -214 c39 -16 78 -56 55 -56 -32 0 -77 23
-93 46 -18 27 -9 30 38 10z m-168 -36 c3 -5 -3 -10 -14 -10 -12 0 -21 5 -21
10 0 6 6 10 14 10 8 0 18 -4 21 -10z m183 -131 l33 -30 -23 -19 c-32 -27 -57
-34 -73 -20 -26 22 -12 100 18 100 7 0 27 -14 45 -31z m-480 -50 c-2 -6 -8
-10 -13 -10 -5 0 -11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13 -11z m1607 -149
c0 -22 -39 -19 -43 3 -3 15 1 18 20 15 12 -2 23 -10 23 -18z m-1765 -13 c0
-19 -47 -60 -59 -52 -18 10 -13 33 11 49 27 19 48 20 48 3z m1730 -17 c0 -5
-7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m53 -70
c10 -15 22 -19 46 -16 17 2 33 0 36 -4 5 -9 -45 -33 -53 -25 -3 2 -11 0 -17
-5 -17 -14 -113 -22 -120 -10 -3 5 -26 7 -50 3 -43 -5 -45 -5 -45 20 0 24 3
25 44 24 24 -1 39 -5 33 -9 -8 -5 -7 -8 3 -8 8 0 22 5 30 10 8 5 11 10 6 10
-6 0 -1 7 10 15 27 20 60 18 77 -5z"/>
<path d="M6618 7823 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7406 8785 c4 -8 8 -15 10 -15 2 0 4 7 4 15 0 8 -4 15 -10 15 -5 0
-7 -7 -4 -15z"/>
<path d="M7419 8725 c-4 -6 -9 -22 -13 -37 -8 -33 -15 -34 -122 -13 -91 17
-96 15 -131 -63 -34 -77 -30 -100 22 -142 24 -19 44 -40 44 -46 1 -6 -18 -17
-41 -24 -63 -18 -81 -56 -30 -62 l27 -4 -27 -12 c-16 -6 -28 -14 -28 -17 0 -4
5 -3 11 1 6 3 24 -10 41 -30 16 -20 34 -34 39 -31 18 12 9 -20 -14 -49 l-24
-29 -27 36 c-16 21 -33 37 -38 37 -5 0 -8 -24 -6 -52 l3 -53 75 -5 c72 -5 74
-5 43 -17 -18 -7 -33 -14 -33 -17 0 -2 15 0 33 5 39 11 119 20 209 23 40 1 73
8 84 17 16 13 16 12 6 -6 -6 -12 -67 -75 -136 -142 -69 -66 -123 -123 -121
-125 9 -9 277 30 289 42 6 6 11 16 11 22 0 6 12 15 27 19 15 4 60 34 100 66
126 102 135 107 168 93 16 -7 34 -9 41 -5 6 4 15 1 20 -6 6 -10 27 -13 68 -12
47 1 63 -3 76 -17 9 -10 24 -16 32 -13 13 5 13 3 4 -15 -13 -24 -40 -29 -60
-9 -10 10 -11 8 -4 -8 14 -36 45 -71 50 -57 7 19 33 14 33 -7 0 -11 11 -33 25
-48 21 -24 26 -26 35 -13 9 13 12 12 25 -4 8 -11 15 -25 15 -32 0 -6 16 -24
35 -40 19 -15 37 -35 40 -43 3 -9 23 -27 45 -41 22 -14 41 -28 41 -32 2 -13 5
-22 17 -51 7 -16 12 -34 12 -41 0 -20 34 -46 59 -46 12 0 33 7 46 16 27 18 22
23 55 -61 5 -14 13 -25 18 -25 12 0 47 62 47 84 0 25 23 33 31 11 4 -10 -2
-24 -15 -36 -23 -22 -27 -39 -10 -56 8 -8 7 -16 -2 -31 -11 -18 -10 -24 6 -42
11 -13 25 -18 37 -14 20 6 22 1 34 -61 l7 -40 8 44 c10 55 12 56 42 37 22 -15
25 -15 37 -1 14 17 60 17 72 0 4 -5 8 19 8 56 0 60 -2 67 -32 96 -36 34 -76
53 -113 53 -28 0 -39 38 -15 57 13 11 12 12 -7 13 -15 0 -21 5 -17 14 3 8 -12
27 -37 47 -23 18 -63 55 -88 82 -45 48 -101 86 -101 68 0 -5 -19 -27 -42 -49
-36 -35 -44 -39 -62 -30 -29 15 -36 29 -29 55 3 14 1 23 -6 23 -6 0 -11 7 -11
15 0 9 6 15 13 14 6 -1 19 1 27 4 8 3 31 8 50 9 l35 3 -78 65 c-44 36 -93 77
-110 92 -18 15 -43 28 -57 28 -29 0 -50 16 -51 40 -1 26 -60 66 -159 109 -52
23 -100 41 -106 41 -10 0 -124 47 -132 55 -1 1 7 26 18 55 11 29 19 58 17 64
-3 6 -10 -1 -16 -17 -16 -41 -86 -132 -120 -156 -44 -31 -112 -61 -142 -61
-15 0 -32 -6 -39 -14 -16 -20 -150 -87 -160 -81 -5 3 -2 21 6 40 19 46 19 61
-1 45 -8 -7 -15 -10 -15 -7 0 12 25 63 39 80 13 16 12 20 -15 48 -23 24 -35
29 -56 25 -20 -4 -28 -1 -28 8 0 8 -6 17 -12 19 -7 3 2 3 20 0 23 -4 32 -2 32
8 0 14 109 69 135 69 7 0 18 7 25 15 16 20 4 85 -15 85 -18 0 -20 33 -4 58 8
13 9 31 3 58 -6 21 -12 34 -15 29z m-175 -87 c9 -36 7 -48 -9 -48 -16 0 -35
30 -35 54 0 25 37 19 44 -6z m78 -33 c4 -17 1 -25 -8 -25 -8 0 -14 11 -14 25
0 14 4 25 9 25 5 0 11 -11 13 -25z m-55 -41 c4 -11 8 -12 13 -4 5 8 16 3 34
-16 14 -16 26 -33 26 -39 -1 -18 -27 -45 -44 -45 -11 0 -14 -4 -9 -12 4 -7 -9
3 -30 23 -37 36 -47 59 -26 59 6 0 13 -9 16 -20 3 -11 9 -20 14 -20 13 0 11
14 -6 36 -17 22 -20 54 -5 54 6 0 13 -7 17 -16z m93 6 c0 -5 -2 -10 -4 -10 -3
0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-72 -271 c3 -23 0 -26 -22
-22 -27 6 -30 11 -20 38 10 24 39 13 42 -16z m-73 11 c3 -5 2 -10 -4 -10 -5 0
-13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m514 -21 c-8 -5 -23 -9 -34
-8 -18 0 -17 1 5 13 26 15 52 10 29 -5z m15 -48 c8 -13 -20 -33 -40 -29 -17 3
-17 4 0 21 19 19 32 22 40 8z m181 -12 c11 -16 1 -19 -13 -3 -7 8 -8 14 -3 14
5 0 13 -5 16 -11z m-302 -16 c11 -10 -24 -43 -45 -43 -25 0 -23 14 5 33 25 18
31 20 40 10z m472 -62 c50 -49 69 -81 46 -81 -15 0 -121 113 -121 128 0 21 14
12 75 -47z m-145 29 c0 -21 -15 -27 -25 -10 -7 12 2 30 16 30 5 0 9 -9 9 -20z
m-222 -36 c-4 -39 -49 -61 -86 -45 l-26 12 25 22 c67 62 94 65 87 11z m474
-96 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m289 -291 c-8
-8 -11 -7 -11 4 0 9 3 19 7 23 4 4 9 2 11 -4 3 -7 -1 -17 -7 -23z"/>
<path d="M5516 8433 c-8 -19 -7 -20 18 -25 26 -4 32 18 8 31 -16 8 -21 7 -26
-6z"/>
<path d="M5655 8350 c3 -4 14 -10 25 -13 11 -3 18 -2 15 3 -3 4 -14 10 -25 13
-11 3 -18 2 -15 -3z"/>
<path d="M5731 8336 c-8 -9 -7 -13 2 -14 6 0 32 -4 57 -8 40 -6 43 -5 30 9
-21 21 -76 29 -89 13z"/>
<path d="M7437 8333 c-16 -3 -26 -10 -24 -16 5 -15 66 -27 73 -15 7 11 -3 39
-13 37 -4 -1 -21 -4 -36 -6z"/>
<path d="M7465 8270 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M4957 7860 c-53 -52 -54 -57 -2 -39 26 10 75 65 75 84 0 16 -28 -1
-73 -45z"/>
<path d="M6066 7881 c-4 -5 10 -24 31 -43 20 -18 70 -66 110 -107 70 -72 98
-87 73 -41 -14 26 -189 200 -201 200 -4 0 -10 -4 -13 -9z"/>
<path d="M5292 7852 c-9 -6 -4 -16 16 -35 25 -24 31 -26 41 -13 10 11 9 19 -1
35 -13 22 -34 26 -56 13z"/>
<path d="M7770 7837 c-25 -12 -56 -30 -70 -40 -22 -15 -22 -17 -5 -17 11 0 41
6 66 14 28 9 51 11 61 5 22 -12 58 39 37 52 -21 13 -40 10 -89 -14z"/>
<path d="M8150 7855 c0 -2 10 -10 23 -16 20 -11 21 -11 8 4 -13 16 -31 23 -31
12z"/>
<path d="M5086 7818 c-8 -13 -22 -43 -32 -68 -24 -63 -30 -67 -58 -41 l-24 23
-11 -52 c-6 -28 -11 -59 -11 -68 0 -24 -19 -42 -46 -42 -25 0 -38 13 -51 54
-13 43 -28 42 -57 -1 -14 -21 -34 -38 -44 -38 -9 0 -21 -9 -26 -21 -5 -11 -22
-28 -38 -37 -31 -18 -36 -38 -14 -56 15 -13 16 -11 56 54 10 17 11 17 6 2 -3
-11 -9 -31 -12 -45 -4 -15 -11 -32 -15 -38 -6 -7 2 -26 20 -51 16 -22 45 -71
65 -109 42 -81 63 -110 72 -101 4 3 14 50 24 104 21 112 28 133 44 133 6 0 36
-26 66 -59 47 -50 65 -62 125 -81 47 -15 84 -36 112 -61 27 -25 53 -39 70 -40
16 0 22 -3 16 -6 -7 -2 -13 -11 -13 -19 0 -12 -9 -14 -44 -9 -48 8 -97 -11
-119 -45 -6 -11 -17 -20 -24 -20 -7 0 -13 -4 -13 -10 0 -10 55 -94 77 -117 6
-7 15 -23 19 -36 4 -12 18 -38 32 -57 15 -20 1 -10 -33 25 -31 33 -59 55 -62
48 -2 -6 10 -40 26 -75 26 -53 28 -64 15 -66 -27 -6 -186 49 -269 93 -55 29
-103 47 -152 55 -48 8 -77 18 -90 32 -10 11 -35 34 -57 51 -34 27 -39 29 -48
14 -5 -9 -14 -17 -21 -17 -21 0 -86 60 -81 74 3 9 -12 26 -40 46 -25 17 -46
35 -46 40 0 6 -9 10 -21 10 -21 0 -82 53 -91 80 -3 8 -8 12 -12 9 -9 -9 64
-147 94 -176 14 -13 59 -49 100 -78 41 -30 81 -60 89 -67 44 -39 75 -57 82
-47 5 8 20 4 50 -12 52 -28 153 -99 157 -111 2 -4 18 -8 35 -8 18 0 38 -4 46
-9 8 -5 27 -3 49 6 46 19 137 7 322 -42 74 -19 182 -47 238 -62 99 -26 105
-29 122 -63 32 -63 37 -64 172 -54 l121 9 18 -43 c26 -59 42 -46 36 30 -4 60
-4 60 28 74 35 14 110 8 390 -33 154 -22 170 -22 355 6 85 13 181 27 213 31
32 4 60 10 63 13 2 2 -72 6 -165 9 -183 6 -193 9 -207 62 -11 46 -39 45 -67
-3 -23 -39 -51 -45 -282 -56 l-151 -7 -19 73 c-32 126 -56 142 -76 53 -10 -45
-10 -57 1 -64 7 -4 10 -12 6 -18 -3 -6 2 -15 11 -21 17 -9 17 -10 0 -17 -19
-7 -50 -4 -192 20 -139 23 -332 76 -278 76 14 0 38 62 48 118 7 41 -1 52 -21
32 -7 -7 -16 21 -29 87 -31 170 -25 162 -143 191 -56 14 -102 29 -102 33 0 9
125 94 173 118 15 8 27 21 27 30 0 37 -32 168 -46 186 -8 11 -27 39 -42 62
-14 24 -31 44 -37 47 -6 2 -46 -2 -88 -10 -131 -22 -317 -19 -317 7 0 11 55
93 97 147 l34 42 -35 0 c-26 0 -39 -6 -50 -22z m238 -204 c13 -5 10 -16 -20
-72 -29 -53 -34 -69 -26 -83 10 -15 4 -25 -35 -63 -54 -51 -67 -49 -97 11 -11
21 -35 63 -53 94 -18 31 -33 61 -33 67 0 5 39 15 88 22 73 11 152 25 159 29 1
1 8 -2 17 -5z m-280 -136 c16 -79 -7 -92 -31 -18 -24 76 -25 77 -32 50 -4 -16
-15 -26 -30 -28 -12 -2 -25 -10 -28 -18 -3 -8 -10 -14 -17 -14 -6 0 -37 -20
-68 -45 -32 -25 -60 -45 -63 -45 -3 0 -5 9 -5 20 0 17 12 26 23 18 8 -6 86 60
137 115 l55 59 26 -32 c15 -17 30 -45 33 -62z m286 -2 c0 -3 -4 -8 -10 -11 -5
-3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m42 -461 c21 -38 38 -77 38
-85 0 -8 -24 9 -53 38 -29 29 -60 52 -70 52 -9 0 -25 -17 -37 -40 -11 -22 -25
-40 -31 -40 -11 0 -48 76 -49 98 0 10 12 13 40 11 31 -3 45 2 63 19 35 36 57
25 99 -53z m-57 -245 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6
0 13 -4 16 -10z"/>
<path d="M4993 7815 c-3 -9 -3 -18 -1 -21 8 -8 58 15 58 26 0 16 -50 12 -57
-5z"/>
<path d="M8114 7818 c3 -7 9 -21 12 -30 8 -21 34 -24 34 -4 0 28 -12 46 -32
46 -12 0 -17 -5 -14 -12z"/>
<path d="M8150 7725 c0 -11 -12 -45 -27 -77 -30 -65 -30 -65 -128 -47 -91 15
-248 38 -268 39 -23 0 -109 -112 -125 -164 -26 -86 -30 -117 -19 -127 7 -5 54
-36 106 -70 51 -33 91 -62 88 -65 -3 -3 -47 -16 -97 -29 -102 -26 -106 -28
-115 -66 -7 -27 -7 -27 64 -31 61 -3 74 -7 101 -33 16 -16 30 -32 30 -37 0
-19 -30 -6 -54 23 -25 29 -30 31 -71 24 -25 -3 -45 -8 -45 -10 0 -12 125 -120
138 -120 9 0 26 16 39 35 55 86 74 110 88 110 9 0 18 -8 21 -17 3 -10 12 -33
21 -51 9 -17 14 -35 11 -38 -3 -3 -23 2 -44 11 -21 8 -43 14 -49 11 -6 -2 -32
-35 -58 -74 -26 -38 -57 -78 -68 -88 -19 -17 -21 -16 -84 46 -50 50 -67 62
-77 52 -17 -17 -15 -101 4 -144 l17 -38 98 0 c54 1 118 6 143 11 25 6 60 10
78 10 18 -1 41 3 51 9 21 10 94 150 85 159 -3 3 -33 -23 -65 -59 -32 -36 -59
-60 -59 -53 0 7 6 16 13 20 6 4 22 28 35 53 35 69 44 83 79 120 37 40 42 60
13 60 -10 0 -24 9 -30 20 -6 11 -20 20 -30 20 -11 0 -20 6 -20 14 0 19 -28 28
-50 16 -10 -5 -34 -12 -54 -15 -34 -6 -35 -5 -34 25 1 30 2 31 37 25 30 -5 38
-2 49 15 11 18 10 25 -10 49 -13 15 -44 44 -70 65 -28 21 -48 46 -48 57 0 33
28 21 57 -23 l28 -43 3 29 c2 17 -4 36 -15 50 -21 23 -53 72 -53 81 0 16 27 0
56 -32 l33 -38 1 28 c0 17 -6 30 -17 34 -10 4 -23 13 -29 21 -7 9 -20 13 -32
10 -17 -5 -28 3 -51 34 -36 49 -29 78 19 78 24 0 31 -5 36 -27 10 -41 46 -103
60 -103 17 0 124 -99 124 -115 0 -7 -8 -27 -17 -45 -16 -27 -22 -30 -51 -25
l-33 6 22 -31 c16 -20 28 -28 39 -24 8 4 39 15 69 26 30 10 70 27 89 38 18 11
37 18 41 15 4 -2 24 0 43 6 44 12 52 35 37 106 -11 47 -14 51 -38 50 -24 -2
-26 1 -26 38 0 36 2 40 24 37 19 -2 22 1 18 20 -3 13 -11 52 -18 88 -12 64
-29 103 -29 65z m-196 -201 c4 -16 13 -37 18 -47 14 -27 1 -34 -30 -15 -18 11
-27 26 -28 45 -3 59 24 70 40 17z m73 -59 c3 -9 3 -19 -1 -22 -7 -7 -36 16
-36 28 0 15 30 10 37 -6z m95 -30 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0
9 5 15 11 13 6 -2 11 -8 11 -13z"/>
<path d="M4255 7395 c30 -25 55 -37 55 -27 0 5 -12 15 -27 23 -43 21 -50 22
-28 4z"/>
<path d="M8869 7373 c-12 -123 -14 -127 -118 -232 -78 -79 -111 -105 -176
-138 -73 -36 -113 -49 -258 -82 -26 -6 -64 -21 -85 -34 -64 -40 -242 -105
-372 -136 -69 -16 -143 -37 -165 -46 -45 -17 -54 -18 119 11 55 9 112 13 127
10 28 -5 267 26 303 40 18 7 18 6 1 -19 -12 -19 -13 -27 -5 -27 11 0 85 50
158 107 12 10 27 17 33 15 9 -2 64 31 79 48 3 3 32 25 65 50 33 24 69 52 80
61 11 10 49 37 85 61 49 32 74 59 105 108 36 57 40 72 42 132 0 38 -3 71 -8
74 -5 3 -9 2 -10 -3z"/>
<path d="M4242 7340 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M8721 7294 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4416 7241 c-14 -15 -26 -30 -26 -33 0 -10 77 -20 100 -13 24 8 14
33 -23 58 -23 15 -26 14 -51 -12z"/>
<path d="M4745 7200 c-19 -7 -19 -8 5 -18 14 -5 27 -7 30 -4 3 2 -1 7 -8 9 -6
3 -10 9 -6 14 6 10 6 10 -21 -1z"/>
<path d="M4660 7112 c0 -5 39 -20 88 -35 71 -22 89 -24 96 -13 8 11 15 10 40
-4 36 -21 132 -35 140 -21 15 24 -8 39 -66 46 -34 4 -74 11 -90 15 -22 7 -28
5 -28 -7 0 -12 -8 -14 -36 -10 -20 3 -43 12 -51 19 -17 14 -93 22 -93 10z"/>
<path d="M8250 7089 c-8 -5 -32 -9 -53 -9 -38 0 -77 -14 -77 -28 0 -18 57 -39
94 -34 30 3 37 2 32 -9 -3 -8 -9 -22 -12 -31 -4 -10 -10 -18 -15 -18 -5 0 -9
-5 -9 -11 0 -6 9 -8 23 -3 12 4 32 7 45 6 19 -2 21 1 12 17 -5 11 -10 42 -10
70 0 53 -7 65 -30 50z"/>
<path d="M6906 7074 c-5 -14 -4 -15 9 -4 17 14 19 20 6 20 -5 0 -12 -7 -15
-16z"/>
<path d="M7550 7025 c0 -28 15 -34 24 -9 10 25 7 34 -9 34 -9 0 -15 -9 -15
-25z"/>
<path d="M7194 6912 c-9 -6 -16 -61 -18 -157 -1 -40 -21 -63 -56 -65 -8 0 -24
-7 -35 -16 -18 -13 -17 -14 15 -8 19 3 49 10 65 14 17 5 41 11 55 14 25 5 25
6 19 88 -5 70 -21 138 -31 138 -2 0 -8 -4 -14 -8z"/>
<path d="M7270 6673 c-69 -6 -146 -25 -197 -49 -28 -13 -56 -24 -61 -24 -5 0
-15 -6 -21 -14 -9 -11 -7 -24 11 -60 26 -55 42 -55 46 0 2 21 6 42 10 46 4 4
45 0 92 -9 47 -8 123 -20 170 -27 92 -13 112 -30 68 -58 -26 -17 -189 -38
-291 -38 -34 0 -96 -7 -137 -15 -41 -9 -104 -20 -140 -25 -171 -23 -168 -23
-184 -7 -35 34 -28 36 261 54 65 4 104 10 107 18 5 15 -35 79 -43 68 -12 -16
-31 -13 -31 6 0 12 8 21 23 24 12 2 -18 2 -67 -1 -50 -2 -101 -8 -115 -13 -13
-6 -70 -9 -127 -8 -56 1 -107 -2 -114 -6 -6 -4 -18 -1 -26 6 -21 17 -267 48
-374 48 -57 -1 -77 -4 -60 -9 21 -7 22 -8 5 -9 -17 -1 -18 -3 -5 -11 13 -9 12
-12 -5 -25 -11 -7 -32 -24 -46 -37 l-26 -23 66 -15 66 -16 -178 0 c-97 -1
-180 -4 -183 -7 -3 -3 6 -18 20 -34 28 -30 34 -48 12 -40 -7 3 -35 9 -61 13
-36 5 -46 4 -41 -5 14 -23 88 -63 107 -58 11 3 26 -1 33 -9 8 -8 19 -14 25
-14 7 0 41 -53 41 -66 0 -6 -76 9 -93 18 -10 5 -21 7 -24 5 -3 -3 13 -15 36
-27 23 -11 41 -24 41 -29 0 -4 19 -16 41 -27 42 -18 150 -99 176 -132 44 -53
-39 142 -89 209 -19 26 -20 31 -8 39 26 17 37 11 93 -50 56 -60 70 -63 67 -12
-3 45 -1 52 19 52 12 0 21 -9 25 -25 4 -16 13 -25 26 -25 15 0 20 -7 20 -27 0
-16 3 -44 6 -64 6 -36 5 -36 -32 -44 -22 -4 -42 -12 -46 -18 -10 -17 3 -121
19 -139 8 -9 30 -19 49 -23 24 -4 38 -13 44 -30 12 -33 25 -37 66 -26 29 8 43
7 66 -5 16 -8 35 -13 41 -10 7 2 5 5 -4 5 -22 1 -35 38 -31 93 l3 47 52 3 c57
3 57 3 44 83 l-7 40 18 -45 c15 -39 22 -45 51 -48 23 -2 37 2 47 15 12 17 13
16 14 -10 0 -28 1 -28 55 -26 30 1 57 0 60 -2 2 -3 6 -25 7 -49 2 -25 6 -49
11 -55 42 -57 38 -55 101 -46 47 6 65 14 84 36 20 24 23 34 17 76 -6 41 -4 49
9 49 9 0 21 -10 26 -22 8 -21 9 -22 11 -3 1 11 1 25 0 31 -1 6 5 17 14 24 13
10 9 16 -22 37 -22 15 -38 20 -42 14 -9 -14 -263 -2 -258 13 2 6 21 14 43 19
64 13 65 27 1 27 -45 0 -58 4 -66 19 -6 10 -7 21 -4 25 10 10 223 7 223 -3 0
-5 -7 -11 -15 -15 -8 -3 -15 -10 -15 -15 0 -7 8 -7 23 -2 16 6 21 16 19 33 -1
16 9 35 33 58 36 35 46 56 20 47 -8 -4 -17 -2 -20 3 -7 12 216 0 232 -12 14
-11 7 -62 -13 -95 -8 -12 -14 -30 -14 -38 0 -9 -13 -30 -30 -47 -16 -16 -30
-33 -30 -37 0 -4 31 -18 70 -31 l69 -23 35 24 c50 34 51 46 3 47 -22 1 -42 4
-45 7 -8 8 20 36 73 72 27 20 41 32 30 28 -11 -4 -28 -10 -37 -12 -10 -3 -18
-9 -18 -13 0 -13 -42 -20 -59 -10 -10 6 -11 10 -4 10 7 0 42 18 78 40 74 45
89 49 80 19 l-7 -20 44 21 c75 37 101 48 159 64 31 9 78 33 105 53 l48 37 -34
8 c-33 8 -124 3 -162 -8 -10 -3 -18 -1 -18 5 0 13 21 18 100 27 134 14 133 13
45 28 -59 10 -73 10 -88 -3 -16 -13 -20 -11 -46 20 -27 32 -30 33 -55 20 -18
-10 -25 -20 -20 -33 4 -13 -2 -21 -23 -29 -15 -6 -36 -18 -45 -26 -17 -15 -18
-15 -18 1 0 29 27 72 66 101 36 28 38 28 141 22 75 -4 124 -13 178 -32 61 -22
78 -25 90 -15 8 7 15 15 15 19 0 3 -35 19 -78 34 -99 35 -139 60 -160 98 -12
21 -24 29 -42 28 -53 -3 -68 1 -73 21 -5 18 -13 20 -94 18 -48 0 -104 -3 -123
-5z m-662 -205 c3 -26 1 -28 -32 -28 -20 0 -36 -4 -36 -9 0 -5 -10 -11 -22
-14 -19 -5 -20 -8 -7 -23 12 -15 12 -17 -1 -12 -23 7 -18 42 7 53 16 6 21 15
17 27 -8 27 5 39 39 36 27 -2 33 -8 35 -30z m-305 -45 c-13 -2 -33 -2 -45 0
-13 2 -3 4 22 4 25 0 35 -2 23 -4z m977 -81 c0 -4 -35 -14 -77 -20 -121 -20
-179 -25 -170 -16 4 5 23 7 42 5 19 -1 40 1 47 6 7 4 45 13 83 19 39 6 71 12
73 13 1 0 2 -3 2 -7z m-642 -18 c12 -8 22 -23 22 -33 0 -16 9 -17 85 -13 53 3
85 1 85 -6 0 -11 -75 -32 -118 -32 -16 0 -35 -7 -42 -15 -9 -11 -35 -15 -105
-15 -66 0 -95 -4 -98 -12 -3 -9 -11 -7 -28 8 -22 20 -29 21 -93 12 -58 -8 -71
-7 -86 7 -24 22 -21 65 5 66 61 3 171 -4 180 -12 16 -15 35 -10 35 10 0 29 39
51 90 51 25 0 56 -7 68 -16z m840 -36 c-5 -15 -88 -41 -88 -27 0 5 6 9 14 9 7
0 19 7 26 15 15 18 53 20 48 3z m-831 -190 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13
3 -3 4 -12 1 -19z m-7 -33 c0 -8 -4 -15 -10 -15 -5 0 -7 7 -4 15 4 8 8 15 10
15 2 0 4 -7 4 -15z m-247 -111 c8 -8 -3 -34 -14 -34 -5 0 -9 9 -9 20 0 19 11
26 23 14z"/>
<path d="M6545 6280 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M6586 6270 c-19 -6 -19 -7 -1 -14 10 -3 22 -3 28 3 12 12 -4 20 -27
11z"/>
<path d="M6361 6266 c10 -8 89 -13 89 -5 0 5 -21 9 -47 9 -25 0 -44 -2 -42 -4z"/>
<path d="M5360 6440 c8 -5 24 -10 35 -10 17 0 17 2 5 10 -8 5 -24 10 -35 10
-17 0 -17 -2 -5 -10z"/>
<path d="M5493 6363 c-32 -6 -29 -13 25 -53 26 -19 49 -37 52 -40 3 -3 58 -46
123 -95 65 -50 143 -109 173 -132 47 -36 85 -47 84 -24 -1 13 -72 82 -120 115
-55 38 -129 99 -195 161 -45 41 -97 76 -112 74 -4 -1 -18 -4 -30 -6z"/>
<path d="M7050 6040 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M6715 5880 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M6460 5826 c0 -2 7 -9 15 -16 13 -11 14 -10 9 4 -5 14 -24 23 -24 12z"/>
<path d="M6505 5790 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M6624 5785 c-10 -8 -14 -15 -9 -15 6 0 3 -5 -5 -10 -8 -5 -10 -10 -4
-10 13 0 49 38 42 45 -2 3 -13 -2 -24 -10z"/>
<path d="M2178 5095 c-5 -5 -8 -118 -8 -252 l0 -243 108 0 c135 0 176 11 228
64 53 52 68 105 61 208 -4 63 -11 87 -34 123 -42 69 -100 93 -238 101 -60 3
-113 3 -117 -1z m211 -101 c34 -24 51 -74 51 -151 0 -91 -21 -133 -76 -152
-73 -25 -74 -23 -74 159 l0 160 38 0 c21 0 48 -7 61 -16z"/>
<path d="M2657 5094 c-4 -4 -7 -117 -7 -251 l0 -243 60 0 60 0 -2 248 -3 247
-50 3 c-28 2 -54 0 -58 -4z"/>
<path d="M3011 5091 c-5 -4 -48 -108 -95 -231 -46 -124 -87 -233 -91 -242 -6
-16 0 -18 52 -18 l60 0 18 60 18 60 82 0 83 0 7 -36 c14 -76 22 -84 88 -84 52
0 58 2 52 18 -4 9 -43 118 -88 242 -44 124 -82 227 -84 230 -6 7 -90 8 -102 1z
m73 -209 l25 -72 -54 0 -54 0 26 76 c14 41 27 73 29 71 2 -2 15 -36 28 -75z"/>
<path d="M3373 5092 c-8 -5 -17 -87 -27 -244 -9 -130 -16 -239 -16 -242 0 -3
25 -6 55 -6 l55 0 2 83 c1 45 3 101 3 125 0 23 3 42 6 42 4 0 16 -27 28 -59
12 -32 35 -89 52 -126 l30 -66 46 3 45 3 52 123 c50 121 51 123 53 80 2 -23 5
-80 9 -125 l6 -83 54 0 54 0 0 33 c-2 85 -28 446 -34 456 -4 6 -25 11 -46 11
-49 0 -49 0 -131 -209 -60 -155 -65 -163 -74 -136 -5 16 -38 99 -71 184 l-61
156 -39 2 c-22 2 -44 0 -51 -5z"/>
<path d="M4096 5090 c-49 -15 -95 -54 -123 -105 -25 -45 -28 -59 -28 -150 0
-88 3 -105 23 -138 13 -20 40 -50 60 -65 31 -23 47 -27 117 -30 68 -3 89 0
122 17 85 43 118 111 117 241 0 96 -20 148 -74 193 -50 43 -141 58 -214 37z
m123 -99 c44 -44 54 -198 17 -268 -28 -55 -98 -56 -138 -2 -38 51 -36 213 3
263 26 33 89 36 118 7z"/>
<path d="M4464 5087 c-2 -7 -3 -118 -2 -247 l3 -235 58 -3 58 -3 -3 146 c-2
80 -1 145 2 145 3 -1 46 -65 96 -143 l92 -142 53 -3 54 -3 -2 248 -2 248 -58
0 -58 0 4 -150 3 -150 -102 153 -102 152 -44 0 c-27 0 -47 -5 -50 -13z"/>
<path d="M4975 5089 c-3 -8 -4 -120 -3 -249 l3 -235 90 -3 c118 -4 164 3 213
35 54 35 83 90 90 168 10 135 -25 218 -113 260 -63 31 -270 48 -280 24z m214
-95 c13 -9 29 -32 37 -50 19 -45 18 -167 -1 -204 -18 -36 -66 -62 -103 -58
l-27 3 -3 150 c-1 82 0 155 2 162 7 18 67 16 95 -3z"/>
<path d="M5450 4852 l0 -252 113 0 c97 0 119 3 162 24 28 13 58 32 67 42 56
64 71 220 29 306 -44 92 -92 114 -263 125 l-108 6 0 -251z m203 147 c43 -20
62 -66 62 -154 0 -116 -36 -165 -121 -165 l-24 0 0 165 0 165 30 0 c16 0 40
-5 53 -11z"/>
<path d="M6033 5093 c-6 -5 -49 -109 -96 -233 -47 -124 -89 -233 -92 -242 -6
-16 0 -18 53 -18 l61 0 17 60 17 60 81 0 81 0 18 -60 17 -60 61 0 c54 0 60 2
54 18 -4 9 -43 116 -87 237 -43 121 -83 223 -87 228 -11 11 -84 18 -98 10z
m71 -211 l25 -72 -50 0 c-27 0 -49 5 -49 10 0 18 42 142 46 137 2 -2 15 -36
28 -75z"/>
<path d="M6286 5093 c-3 -4 -6 -24 -6 -45 l0 -38 55 0 55 0 0 -205 0 -205 60
0 60 0 2 203 3 202 56 3 c56 3 57 3 62 39 4 20 4 39 2 44 -6 9 -340 11 -349 2z"/>
<path d="M6793 5088 c-17 -23 -183 -459 -183 -480 0 -5 26 -8 57 -6 l57 3 17
55 16 55 81 3 80 3 19 -58 20 -58 56 -3 c64 -3 68 3 43 60 -8 18 -47 120 -85
227 -39 107 -76 198 -83 203 -20 12 -85 9 -95 -4z m69 -186 c9 -32 20 -66 24
-75 5 -15 -1 -17 -46 -17 -45 0 -51 2 -46 17 4 9 15 43 24 75 9 32 19 58 22
58 3 0 13 -26 22 -58z"/>
<path d="M7194 5082 c-60 -29 -84 -64 -84 -127 0 -68 22 -96 111 -141 82 -41
101 -69 75 -109 -21 -31 -46 -32 -113 0 l-53 24 -15 -29 c-23 -44 -19 -54 30
-78 55 -27 160 -30 205 -7 52 27 83 79 84 140 0 47 -4 59 -29 86 -17 17 -58
45 -93 61 -120 57 -94 138 30 93 27 -9 50 -16 52 -14 6 6 36 68 36 72 0 11
-87 38 -138 43 -43 4 -67 0 -98 -14z"/>
<path d="M7513 5092 c-9 -6 -11 -70 -10 -247 l2 -240 163 -3 163 -3 -3 43 -3
43 -102 3 -103 3 0 59 0 60 74 0 c79 0 85 5 86 68 0 21 -4 22 -81 22 l-80 0 3
53 3 52 98 3 97 3 0 38 c0 23 -5 41 -13 44 -22 8 -281 8 -294 -1z"/>
<path d="M7885 5089 c-3 -8 -4 -120 -3 -249 l3 -235 58 -3 57 -3 0 106 c0 78
3 105 13 105 30 0 57 -35 93 -120 l38 -90 64 0 c35 0 62 3 60 8 -1 4 -12 30
-22 57 -11 28 -36 76 -57 108 l-38 58 31 28 c26 23 32 37 35 82 4 45 1 60 -19
88 -30 44 -74 59 -203 67 -85 6 -106 4 -110 -7z m170 -79 c55 -21 51 -113 -6
-125 -42 -9 -49 0 -49 64 0 74 5 80 55 61z"/>
<path d="M8244 5089 c-3 -5 -2 -13 1 -19 3 -5 16 -38 29 -72 13 -35 48 -130
79 -213 30 -82 58 -158 62 -167 6 -14 19 -18 59 -18 l51 0 88 239 c48 131 87
244 87 250 0 9 -107 8 -115 -1 -3 -3 -91 -272 -104 -316 -5 -17 -17 13 -51
120 -67 217 -58 203 -124 204 -31 1 -59 -3 -62 -7z"/>
<path d="M8760 4850 l0 -250 60 0 60 0 0 250 0 250 -60 0 -60 0 0 -250z"/>
<path d="M9105 5088 c-48 -17 -93 -56 -118 -101 -19 -36 -22 -57 -22 -147 0
-94 3 -110 25 -148 15 -26 42 -54 67 -69 39 -23 51 -25 137 -21 52 1 98 6 102
11 12 12 -10 82 -25 79 -105 -22 -142 -10 -167 54 -23 61 -17 192 12 231 26
35 67 41 112 17 32 -17 32 -17 62 11 l30 29 -20 21 c-38 40 -130 56 -195 33z"/>
<path d="M9382 5091 c-8 -5 -11 -80 -9 -246 l2 -240 163 -3 163 -3 -3 43 -3
43 -100 5 -100 5 0 55 0 55 70 3 c75 3 85 10 85 59 l0 32 -77 3 -78 3 0 50 0
50 98 3 98 3 -3 42 -3 42 -146 1 c-81 1 -151 -1 -157 -5z"/>
<path d="M10096 5090 c-53 -16 -96 -54 -123 -109 -23 -47 -27 -68 -27 -140 0
-104 23 -164 80 -207 36 -28 42 -29 140 -29 57 0 108 5 113 10 7 7 7 22 -1 48
-9 28 -16 36 -27 31 -9 -3 -36 -9 -61 -11 -36 -4 -51 -1 -72 16 -64 51 -59
279 7 305 29 11 70 6 94 -10 22 -16 24 -15 55 15 l31 32 -23 19 c-43 36 -123
48 -186 30z"/>
<path d="M10470 5085 c-97 -31 -144 -111 -144 -245 0 -103 23 -163 80 -206 33
-26 47 -29 119 -32 68 -3 89 0 123 17 81 41 122 120 122 234 0 107 -40 186
-114 224 -42 22 -132 26 -186 8z m135 -101 c14 -15 27 -45 31 -71 9 -64 -1
-169 -20 -198 -31 -47 -101 -44 -138 5 -40 54 -36 218 7 264 33 36 87 36 120
0z"/>
<path d="M10875 5088 c-7 -18 -33 -471 -28 -480 2 -5 26 -8 52 -8 l48 0 7 46
c3 26 6 86 6 133 l1 86 25 -65 c13 -36 34 -85 45 -110 12 -24 25 -55 30 -67 7
-19 16 -23 54 -23 l45 0 28 63 c15 34 39 90 51 125 13 34 28 62 33 62 5 0 6
-5 3 -10 -4 -6 -3 -62 1 -125 l7 -115 53 0 54 0 0 33 c-1 27 -28 457 -29 465
-3 9 -90 0 -98 -10 -8 -10 -92 -218 -138 -343 -6 -16 -8 -17 -12 -5 -33 95
-137 346 -146 352 -20 12 -87 9 -92 -4z"/>
<path d="M9775 4706 c-26 -19 -26 -81 1 -101 43 -33 104 -2 104 53 0 53 -61
80 -105 48z"/>
</g>
</svg>



		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
